<template>
  <v-card class="elevation-0">
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Countries
          </v-col>
          <v-col>
            <v-btn class="float-right mr-5" color="primary" @click="showCreateItem()">Add</v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="deleteItem(item.id)">
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>

        <v-card-title>
          <span class="headline">New country</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form ref="form">
                  <v-autocomplete v-model="item.countryID" :items="activeCountries" item-value="id" item-text="name" label="Country"
                  required :rules="[v => !!v || 'Country is required']"></v-autocomplete>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'RegionsCountriesTable',

  props: {
    regionID: { type: Number, required: true },
  },

  data () {
    return {
      headers: [
        { text: 'ISO2', value: 'country_iso2' },
        { text: 'Name', value: 'country' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      item: {
        countryID: null
      },
      showModal: false,
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'regionsCountries_options',
      endpoint: '/a/common/regions-countries',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries
    },
    fetchEndpoint () {
      return '/a/common/regions/' + this.regionID + '/countries'
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    closeModal: function () {
      this.item.countryID = null
      this.showModal = false
    },

    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }
      
      var myURL = this.endpoint + '/' + id
      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.deleting = false)
    },

    fetchData: function () {
      this.loading = true
      var myURL = this.fetchEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      var body = JSON.stringify({
        'regions_fk': this.regionID,
        'countries_fk': this.item.countryID
      })
      this.$http.post(this.endpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.endpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.showModal = true
    }
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
